<template>
  <div class="data_one_day">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <el-date-picker
      v-model="date"
      type="date"
      placeholder="请选择日期"
      value-format="yyyy-MM-dd"
      @change="handleUserDate"
      :picker-options="pickerOptions"
    >
    </el-date-picker>

    <div class="data_one_day_c">
      <div class="data_one_day_list">
        <div class="data_one_day_list_title">
          <p>实名认证</p>
          <p style="font-size: 24px">{{ userTotal }}</p>
        </div>
        <el-table
          :data="userData"
          width="100%"
          :header-cell-style="{
            background: 'rgb(241 241 243)',
          }"
        >
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="authTime" label="实名日期" width="180">
          </el-table-column>
          <el-table-column prop="regTime" label="注册日期" width="180">
          </el-table-column>
          <el-table-column prop="realName" label="真实姓名"> </el-table-column>
        </el-table>
      </div>

      <div class="data_one_day_list">
        <div class="data_one_day_list_title">
          <p>车主认证</p>
          <p style="font-size: 24px">{{ driverTotal }}</p>
        </div>
        <el-table
          :data="driverData"
          width="100%"
          :header-cell-style="{
            background: 'rgb(241 241 243)',
          }"
        >
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column
            prop="driverAuthTime"
            label="车主认证时间"
            width="180"
          >
          </el-table-column>
          <el-table-column prop="regTime" label="注册日期" width="180">
          </el-table-column>
          <el-table-column prop="realName" label="真实姓名"> </el-table-column>
        </el-table>
      </div>

      <div class="data_one_day_list">
        <div class="data_one_day_list_title">
          <p>趣宝盒</p>
          <p style="font-size: 24px">{{ boxTotal }}</p>
        </div>
        <el-table
          :data="boxData"
          width="100%"
          :header-cell-style="{
            background: 'rgb(241 241 243)',
          }"
        >
          <el-table-column prop="uphone" label="手机号"></el-table-column>
          <el-table-column prop="pphone" label="推荐人"></el-table-column>
          <el-table-column
            prop="hardwareId"
            label="盒子ID"
            width="180"
          ></el-table-column>
          <el-table-column prop="realName" label="真实姓名"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { format_time_date } from "@/util/funBusiness";

export default {
  components: { Breadcrumb },
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "统计",
        },
        {
          name: "单日数据",
        },
      ], // 面包屑数据
      pickerOptions: {
        disabledDate(time) {
          // 获取时间选择器的月份信息
          let timeday = time.getDate(); // 获取时间选择器的月份

          if (timeday >= 1 && timeday < 10) {
            timeday = "0" + timeday;
          }

          //小于指定时间都不可选
          return time.getTime() > new Date().getTime();
        },
      },
      date: "",

      userData: [],
      userTotal: "" || 0,
      driverData: [],
      driverTotal: "" || 0,
      boxData: [],
      boxTotal: "" || 0,
    };
  },

  methods: {
    handleUserDate() {
      const optUser = {
        url: reqUrl.getDailyUserDate,
        method: "POST",
        params: JSON.stringify({
          queryDay: this.date,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          } else if (res.data.code == 0) {
            this.userData = res.data.data.list;
            this.userTotal = res.data.data.total;

            if (res.data.data.list) {
              this.userData.map((item) => {
                this.changeItem(item);
              });
            }
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
        },
      };
      request(optUser);

      const optDriver = {
        url: reqUrl.getDailyDriverDate,
        method: "POST",
        params: JSON.stringify({
          queryDay: this.date,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          } else if (res.data.code == 0) {
            this.driverData = res.data.data.list;
            this.driverTotal = res.data.data.total;

            if (res.data.data.list) {
              this.driverData.map((item) => {
                this.changeItem(item);
              });
            }
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
        },
      };
      request(optDriver);

      const optBox = {
        url: reqUrl.getDailyHardwareDate,
        method: "POST",
        params: JSON.stringify({
          queryDay: this.date,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          } else if (res.data.code == 0) {
            this.boxData = res.data.data.list;
            this.boxTotal = res.data.data.total;

            if (res.data.data.list) {
              this.boxData.map((item) => {
                this.changeItem(item);
              });
            }
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
        },
      };
      request(optBox);
    },

    changeItem(item) {
      item.authTime = format_time_date(item.authTime);
      item.regTime = format_time_date(item.regTime);
      item.driverAuthTime = format_time_date(item.driverAuthTime);
      item.bindTime = format_time_date(item.bindTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.data_one_day {
  position: relative;
  height: calc(100vh - 40px);
  ::v-deep .el-input__inner {
    border-radius: 13px;
  }
  .data_one_day_c {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .data_one_day_list {
      width: 47.5%;
      height: 100%;
      background: #fff;
      border-radius: 16px;
      padding: 10px;
      margin: 10px 0;

      .data_one_day_list_title {
        margin-bottom: 10px;
        line-height: 30px;
        color: #202226;
      }

      ::v-deep .el-table {
        max-height: 575px;
        overflow-y: scroll;

        .el-table--fit {
          border: 1px solid #ebedf2;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>
